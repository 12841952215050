<template>
  <div class="report">
    <div class="no-print mt-5"></div>
    <ReportPage v-if="report"
                :report="report" :startDate="startDate" :endDate="endDate" :dateGrouping="dateGrouping"
                :related_ids="relatedIds" :timezone="timezone" :related_type="relatedType"
                @data_ready="dataReady = true"></ReportPage>
    <div class="report-header">
      <div><i
        class="icon-button"
        :class="$config.icons.general.back"
        v-b-tooltip
        title="Back to Reports"
        @click="$router.push('/reports')"

      ></i></div>
      <div class="report-heading" v-if="report"><h1>{{  report['name'] }}</h1></div>
      <div class="toolbox no-print" v-if="report && dataReady">
        <i
          class="icon-button mr-2"
          :class="$config.icons.stats.previous"
          v-b-tooltip.hover
          title="Previous Week"
          @click="previousDate()"
        ></i>
        <i
          class="icon-button mr-1"
          :class="$config.icons.stats.config"
          v-b-tooltip.hover
          title="Run with Options"
          @click="selectReport(report, $event)"
        ></i>
        <b-modal id="report-properties" centered class="modal-content" size="m" hide-footer
                 title="Rerun Config">
          <ReportProperties :report="report" modal-id="report-properties" :enable-edit-tabs="false"></ReportProperties>
        </b-modal>
<!--        NOTE: Do not use v-model here, we need to trigger the report to reload-->
        <device-selector
          v-if="deviceSelectMode"
          return_prop="device_id"
          :value="relatedIds"
          :emit_on_load="false"
          :selection_mode="deviceSelectMode"
          force_array
          null_value_as_all
          :dropdown_mode="deviceSelectMode==='multi'"
          @input="setDeviceData"
        />
        <i v-if="deviceSelectMode==='multi'"
          class="icon-button fa fa-play"
          :class="{disabled: !deviceSelectDirty}"
          v-b-tooltip.hover
          title="Rerun"
          @click="pushNextReport"
        ></i>
        <i
          class="icon-button ml-2"
          :class="$config.icons.stats.next"
          v-b-tooltip.hover
          title="Next Date"
          @click="nextDate()"
        ></i>
      </div>
      <div>
        <i v-if="dataReady"
           class="icon-button no-print"
           :class="$config.icons.general.print"
           v-b-tooltip
           title="Print View"
           @click="print()"
        ></i>
        <i v-if="dataReady"
           class="icon-button"
           :class="$config.icons.general.export"
           v-b-tooltip
           title="Export Table"
           @click="$bvModal.show('modal-export-data')"
        ></i>
      </div>
    </div>
  </div>
</template>

<script>

import ReportPage from '@/components/stats/ReportPage'
import ReportProperties from '@/components/stats/ReportProperties'
import * as DataProvider from '@/components/helpers/DataProvider'
import * as ErrorHelper from '@/components/helpers/ErrorHelper'
import ReportHelper from '@/components/helpers/ReportHelper'
import DeviceSelection from '@/components/stats/DeviceSelection.vue'
import DeviceSelector from '@/components/shared/DeviceSelector.vue'

export default {
  name: 'reports-view',
  components: {
    DeviceSelector,
    DeviceSelection,
    ReportPage,
    ReportProperties
  },
  data: function () {
    return {
      reportId: null,
      startDate: null,
      endDate: null,
      dateGrouping: null,
      report: null,
      timezone: null,
      dataReady: false,
      relatedIds: null,
      relatedType: null,
      dateMode: null,
      deviceList: [],
      loadedRelatedIds: null
      // currentDeviceSelectionMode: 'all',
      // currentDeviceSelectionValue: null,
      // defaultDeviceSelectionValue: null,
    }
  },
  async mounted() {
    if (this.$route.params.hasOwnProperty('id')) {
      this.reportId = this.$route.params.id
    }
    if (this.$route.query.hasOwnProperty('start_date')) {
      this.startDate = this.$route.query.start_date
    }
    if (this.$route.query.hasOwnProperty('end_date')) {
      this.endDate = this.$route.query.end_date
    }
    if (this.$route.query.hasOwnProperty('time_grouping')) {
      this.dateGrouping = this.$route.query.time_grouping
    }

    if (this.$route.query.hasOwnProperty('date_mode')) {
      this.dateMode = this.$route.query.date_mode
    }

    if (this.$route.query.hasOwnProperty('timezone')) {
      this.timezone = this.$route.query.timezone
    } else {
      this.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
    }

    if (this.$route.query.hasOwnProperty('related_ids')) {
      if (this.$route.query.related_ids !== null && !Array.isArray(this.$route.query.related_ids)) {
        this.relatedIds = [this.$route.query.related_ids]
      } else {
        this.relatedIds = this.$route.query.related_ids
      }
    }

    if (this.$route.query.hasOwnProperty('related_type')) {
      this.relatedType = this.$route.query.related_type
    }
    if (!this.reportId) {
      console.error('No Report ID provided!')
    } else {
      let resp = await DataProvider.getReport(this.reportId)
      if (resp.success) {
        this.report = resp.data
        this.setMissingFromReport()
      } else {
        ErrorHelper.displayDataErrorToast(resp)
      }
    }
    let resp = await DataProvider.getDeviceList(['device_imei', 'device_name', 'device_code', 'device_id'])
    if (resp.success) {
      this.deviceList = resp.data
    } else {
      ErrorHelper.displayDataErrorToast(resp)
    }
    this.loadedRelatedIds = this.relatedIds
  },
  methods: {
    print() {
      window.print()
    },
    setMissingFromReport() {
      if (!this.startDate) {
        this.startDate = this.report.default_parameters.start_date
      }
      if (!this.endDate) {
        this.endDate = this.report.default_parameters.end_date
      }
      if (!this.dateGrouping) {
        this.dateGrouping = this.report.default_parameters.default_date_grouping
      }
      if (!this.relatedIds) {
        this.relatedIds = this.report.default_parameters.related_ids || null
      }
      if (!this.relatedType) {
        this.relatedType = this.report.default_parameters.related_type
      }
      if (!this.dateMode) {
        this.dateMode = this.report.default_parameters.default_date_mode
      }
    },
    selectReport (report, event) {
      if (event.stopPropagation) {
        event.stopPropagation()
      }
      this.$emit('select', report)
      this.selectedReport = report
      this.$bvModal.show('report-properties')
    },
    async nextDate() {
      this.changeDateByOffset(1)
    },
    async previousDate() {
      this.changeDateByOffset(-1)
    },
    async changeDateByOffset(offset) {
      let newDates = ReportHelper.offsetDateFromReportParams(this.dateMode,
        this.startDate, this.endDate, offset)
      this.startDate = newDates.startDate.format('YYYY-MM-DD')
      this.endDate = newDates.endDate.format('YYYY-MM-DD')
      this.dateMode = newDates.dateMode
      await this.pushNextReport()
    },
    async setDeviceData(newRelatedIds) {
      this.relatedIds = newRelatedIds
      if (!this.deviceSelectDirty) {
        return
      }
      this.relatedType = 'device'
      if (this.deviceSelectMode !== 'multi') {
        await this.pushNextReport()
      }
    },
    async pushNextReport() {
      // ONly report if the start date of the new report is in hte past
      if (this.startDate <= new Date().toISOString().split('T')[0]) {
        // Create a new route with the new dates.
        this.$router.push({
          path: '/reports/' + this.report.id,
          query: {
            start_date: this.startDate,
            end_date: this.endDate,
            date_mode: this.dateMode,
            time_grouping: this.dateGrouping,
            timezone: this.timezone,
            related_ids: this.relatedIds,
            related_type: this.relatedType
          }
        })
      } else {
        this.$bvToast.toast('Cannot report on future dates', {
          title: 'Error',
          variant: 'danger',
          solid: true
        })
      }
    },
    getReportOption (optionName) {
      if (Object.hasOwn(this.report.data, 'report_options') &&
        Object.hasOwn(this.report.data.report_options, optionName)) {
        return this.report.data.report_options[optionName]
      } else {
        return null
      }
    },
  },
  computed: {
    deviceSelectDirty() {
      return (this.loadedRelatedIds === null && this.relatedIds !== null) ||
        (this.loadedRelatedIds !== null && this.relatedIds === null) ||
        ((this.relatedIds && this.loadedRelatedIds) &&
          (!this.relatedIds.every((v, i) => v === this.loadedRelatedIds[i]) || !this.loadedRelatedIds.every((v, i) => v === this.relatedIds[i])))
    },
    deviceSelection() {
      if (this.relatedType) {
        return this.relatedType
      } else {
        return 'all'
      }
    },
    dateParams() {
      return ReportHelper.getReportDatesParamsFromDates(this.startDate, this.endDate,
        this.dateGrouping, this.timezone, this.relatedIds, this.relatedType)
    },
    deviceSelectMode() {
      return this.getReportOption('device_selection_mode')
    },
    deviceSelectionModeOptions () {
      if (this.deviceSelectMode === 'multi') {
        return [
          {
            value: 'all',
            text: 'All Devices'
          },
          {
            value: 'selection',
            text: 'Specific Devices'
          }
        ]
      } else {
        let deviceOptions = [
          {
            value: null,
            text: '- Select a Device -'
          }
        ]
        Object.keys(this.deviceList).forEach(deviceIMEI => {
          deviceOptions.push({
            text: this.deviceList[deviceIMEI].device_name,
            value: this.deviceList[deviceIMEI].device_id
          })
        })
        return deviceOptions
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/variables';

.report {
  display: flex;
  flex-direction: column;
  background-color: $default-content-background;
  width: 100%;
  overflow: auto;
  padding: 1%;
}

.report-heading {
  color: $text-color-heading;
  font-weight: 600;
  padding: 5px;
}

.report-header {
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% - 60px);
  background: $theme-color-background-2;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
}

.icon-button {
  font-size: 2em;
}

.icon-button.disabled {
  color: grey;
  cursor: default;
}
.icon-button.disabled:hover {
  text-shadow: none;
}

.toolbox{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin: 0 auto;
}

@media print {

  @page {
    size: a4 landscape;
  }

  .report {
    overflow: hidden;
    justify-content: start;
    padding: 0;
  }

  .report-heading {
  }

  .report-header {
  }

  .report-header {
    display: none;
  }

  .b-tooltip {
    display: none;
  }
}

</style>
