import { render, staticRenderFns } from "./ReportView.vue?vue&type=template&id=20a8d733&scoped=true&"
import script from "./ReportView.vue?vue&type=script&lang=js&"
export * from "./ReportView.vue?vue&type=script&lang=js&"
import style0 from "./ReportView.vue?vue&type=style&index=0&id=20a8d733&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "20a8d733",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/builds/pro-tekt/protekt_webapp/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('20a8d733')) {
      api.createRecord('20a8d733', component.options)
    } else {
      api.reload('20a8d733', component.options)
    }
    module.hot.accept("./ReportView.vue?vue&type=template&id=20a8d733&scoped=true&", function () {
      api.rerender('20a8d733', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/ReportView.vue"
export default component.exports